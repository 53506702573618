.loginView {
  &__container {
    padding-left: 4rem;

    @media only screen and (max-width: $bp-md) {
      padding: 0 3rem;
    }
  }

  &__error-message {
    margin-left: 2rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form {
    max-width: 450px;
  }

  &__forgotPassword {
    font-family: "Open Sans", "Sans Serif";
    margin-top: 1rem;
    color: #757575;
    cursor: pointer;
  }

  &__heading {
    font-size: 3.25rem;
    font-weight: 600;
    margin-top: 7rem;

    @media only screen and (max-width: $bp-md) {
      margin-top: 4rem;
    }
  }

  &__hero-image {
    width: 100%;
    max-height: 1024px;
    // margin-right: -15px;
  }

  &__homelink {
    color: black;
    margin-top: 3rem;
    font-family: "Sulphur Point", "Sans Serif";

    &:hover {
      color: #ed694a;
      text-decoration: none;
    }
  }

  &__text {
    font-family: "Open Sans", "Sans Serif";
    margin-top: 2rem;
    max-width: 80%;
  }

  &__textInput {
    width: 100%;
    color: #757575;
    border: 1px solid #757575;
    font-family: "Open Sans", "Sans Serif";

    &:focus {
      border: 1px solid #ed694a;
      color: black;
    }

    &:hover {
      border: 1px solid #ed694a;
    }
  }
}
