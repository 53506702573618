.topNavbar {
  background-color: #223b41;
  padding: 1rem;
  &-link {
    color: white;
    transition: all 0.3s;

    &:hover {
      color: #a7e8e0;
    }

    &--orange {
      background-color: #ed694a;
      transition: all 0.3s;

      &:hover {
        color: #ed694a;
        background-color: white;
      }
    }
  }

  &-username {
    color: white;
    margin-right: 1rem;
    padding: .5rem 1rem;
    background-color: #757575;
  }
}

.test {
  stroke: none!important;
  fill:#80AF5A!important;

  &-2 {
    stroke: none!important;
    fill: none!important;
  }
}