.dashboardStartView {
  display: flex;
  flex-wrap: wrap;
  &__container {
    padding: 2rem;
  }

  &__flex-container {
    display: flex;

    &__menu {
      flex: 1 1 15%;
    }

    &__dashboard {
      flex: 1 1 80%;
      position: relative;

      &__icon {
        cursor: pointer;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: .5rem;
      }
    }
  }

  &__heading {
    font-size: 2.8rem;
    line-height: 2;
    font-weight: 600;
    border-bottom: 1px solid #c4c4c4;
  }
}
