.responseDisplay {
  margin-top: 2rem;

  &__card {
    padding: 2rem;
    background-color: #fff;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
    }

    &__info {
      font-family: "Open Sans", "sans serif";
      font-size: 0.9rem;
      margin-top: 1rem;
    }

    &__num-row {
      display: flex;
      justify-content: space-between;
      flex: 0 1 45%;
    }

    &__num-col {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &__info {
        font-size: 0.75rem;
        font-family: "Open Sans", "sans-serif";
      }

      &__num {
        font-family: "Roboto Mono", monospace;
        font-size: 3rem;
        line-height: 1.5;
        font-weight: 700;
        text-align: center;
        letter-spacing: -0.185em;

        &--green {
          color: #2b6b46;
        }

        &--red {
          color: #d44333;
        }
      }
    }
  }
}
