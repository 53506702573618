.dashboardCreateForm {
  &__green-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white;

    background-color: #223b41;
    padding: 1.8rem;
    margin-top: 1.5rem;

    &__black-box {
      height: 100%;
      background-color: #161515;
      padding: 0.5rem 1.5rem;
    }

    &__col {
      flex: 0 0 45%;
      margin-top: 1.5rem;
    }

    &__input {
      border: none;
      margin-top: 2rem;
      padding: 1rem;
      width: 100%;
      font-family: "Open Sans";

      &:focus {
        outline: none;
      }
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
      color: #a7e8e0;
      width: 100%;
    }
  }
}
