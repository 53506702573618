.createQuestionsBlock {
  margin-top: 2rem;

  &__button-row {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-left: 5rem;
  }

  &__num {
    font-family: "Roboto Mono";
    font-size: 3.25rem;
    padding: 1rem;
    color: #161515;
  }

  &__question {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__white-box {
    background-color: #fff;
    flex: 1;
    margin-left: 1rem;
    padding: 1rem;
    display: flex;

    &__label {
      font-size: 0.75rem;
      background-color: #e2e2e2;
      color: #757575;
      padding: 0.5rem;
      width: min-content;
      border: none;
      cursor: pointer;
      display: inline;
      margin-right: 1rem;
    }

    &__edit {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      &__icon {
        margin-left: 1rem;
        padding: 1rem;
        background-color: #a7e8e0;
        cursor: pointer;

        &--grey {
          background-color: #eeeeee;
          cursor: inherit;
        }
      }
    }

    &__question {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__special-button {
      margin-left: 1rem;
      border: 1px solid #a7e8e0;
      transition: all 0.2s;

      &:hover {
        background: #223b41;
        color: #ffffff;
        border: 1px solid transparent;
      }
    }

    &__text-input {
      margin-top: 0.5rem;
      border: none;
      padding: 0.5rem;
      padding-left: 0;
      font-size: 1.5rem;
      font-weight: 600;
      &:focus {
        outline: none;
        color: #161515;
      }
    }

    &__textarea {
      margin-top: 0.5rem;
      border: none;
      padding: 0.5rem;
      font-family: "Open Sans", sans-serif;
      font-size: 0.9rem;
      line-height: 1.5;
      margin-left: -0.5rem;
      &:focus {
        outline: #e4e4e4 1px solid;
        color: #161515;
      }
    }
  }
}
