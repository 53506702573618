.infoDisplayblock {
    background-color: #fff;
    width: 100%;
    min-height: 265px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &-flexItem {
        flex: 0 0 25%;
        position: relative;
        text-align: center;

        &__gray-border {
            border: 1px solid #DADEE5;
            border-radius: 8px;
            width: 220px;
            height: 135px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &__info {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-title {
                color: #797B80;
            }
            &-num {
                color: #333333;
                font-size: 2rem;
                font-family: 'Roboto Mono';
                margin-top: .5rem;
                
            }
        }
    }
}