.addStaffBlock {
  margin-top: 2rem;
  background-color: #edeeef;
  padding: 2rem;
  position: relative;

  // All settings if the component are in dark mode
  &--dark {
    background-color: #223b41;

    & .addStaffBlock__title {
      color: #a7e8e0;
    }

    & .addStaffBlock__title-info {
      color: #e2e2e2;
    }

    & .addStaffBlock__show-staff__info-text {
      color: #eeeeee;
    }

    & .addStaffBlock__icon {
      background-color: #a7e8e0;
    }
  }

  &__icon {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s;

    &--open {
      color: #223b41;
      background-color: #a7e8e0;
    }
  }

  &__show-staff {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    transition: all 5s;

    &__employee-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
    }

    &__info-text {
      width: 50%;
      font-family: "Open Sans", "sans serif";
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }

  &__top-row {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #161515;
    margin: 0;

    &-info {
      font-size: 0.6rem;
      margin-left: 1rem;
      color: #757575;
    }
  }
}
