.inspectForm {
  background-color: #e5e5e5;
  padding: 2rem;

  &__back-icon {
    margin-right: 0.3rem;
  }

  &__dropdown {
    position: relative;

    &__menu {
      position: absolute;
      top: 1rem;
      right: 0;
      transform: translateX(100%);

      &-option {
        padding: 1rem;
        background-color: white;
        &:hover {
          color: white;
          background-color: black;
          cursor: pointer;
        }
      }
    }
  }

  &__heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
    position: relative;

    &__icon {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      cursor: pointer;
      padding: 1rem;
    }
  }

  &__heading--large-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
  }

  &__info-text {
    font-family: "Open sans", "sans-serif";
    font-size: 0.9rem;
    margin-top: 1.5rem;
  }

  &__questions {
    display: flex;
    width: 100%;
    max-width: 53.5rem;
    overflow: auto;

    @media only screen and (max-width: $bp-lg) {
      max-width: 44rem;
    }

    @media only screen and (max-width: $bp-md) {
      max-width: 29.25rem;
    }

    @media only screen and (max-width: $bp-sm) {
      max-width: 28rem;
    }

    &__white-box {
      background-color: white;
      height: 200px;
      width: 410px;
      flex-shrink: 0;
      margin-right: 1.2rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;

      &__name {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 0.9rem;
        font-family: "Open Sans";
      }
    }
  }

  &__top-row {
    cursor: pointer;
  }
}

#orange-scroller::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

#orange-scroller::-webkit-scrollbar {
  height: 8px;
}

#orange-scroller::-webkit-scrollbar-thumb {
  background-color: #ed694a;
}
