.faqView {
  &__Bottomsection {
    &__menu {
      &__container {
        margin-top: 3rem;
      }

      &__row {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;
      }

      &__text {
        margin-left: 2rem;
        line-height: 1.4rem;
        font-weight: 600;

        @media only screen and (max-width: $bp-md) {
          font-size: .8rem;
          margin-left: .5rem;
        }

        &--inactive {
          color: #afafaf;
        }
      }
    }

    &__icon {
      width: 28px;
      height: 8px;
      @media only screen and (max-width: $bp-md) {
          width: 14px;
          height: 4px;
        }
    }

    &__questions {
      &__container {
        margin-top: 5rem;
        margin-bottom: 9rem;
      }
      &__heading {
        margin-top: 2.5rem;
        font-size: 2.8rem;
        margin-bottom: 2rem;
        font-weight: 600;

        @media only screen and (max-width: $bp-md) {
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
        }
      }

      &__question {
        margin-top: 1.8rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 100;

        @media only screen and (max-width: $bp-md) {
          font-size: 1.1rem;
          margin-top: 1.2rem;
        }

        &__icon {
          font-size: 1rem;
        }
      }
    }
  }
}
