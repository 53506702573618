.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal__content {
  position: fixed;
  background: #edeeef;
  width: fit-content;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;

  padding: 1rem 2rem 2rem;

  &__black-box {
    background-color: #161515;
    padding: 1rem;
    color: white;
    margin-top: 2rem;
    max-width: 510px;

    &__heading {
      font-family: "Stratos", "sans-serif";
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0em;
      text-align: left;
      color: #a7e8e0;
      margin-top: 1rem;
    }

    &__text {
      font-family: "Open Sans", "sans-serif";
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  &__button-row {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    text-align: right;
  }

  &__button-empty {
    background-color: transparent;
    color: black;
    padding: 1rem 3rem;
    margin-top: 2rem;
    border: none;
    cursor: pointer;
    margin-right: 2rem;
    transition: all 0.2s;

    &:hover {
      color: #ed694a;
      background-color: #fff;
    }

    &:focus {
      outline: none;
    }
  }

  &__form {
    display: flex;
    margin-top: 2.5rem;
    flex-wrap: wrap;
    max-width: 510px;
    // flex-direction: column;

    &__input {
      padding: 1rem;
      border: none;
      margin-top: 1.5rem;
      width: 100%;

      &:focus {
        outline: 1px solid #ed694a;
      }
    }

    & :last-child {
      margin-right: 0rem;
    }
  }

  &__heading {
    font-size: 1.8rem;
    line-height: 2;
    font-weight: 600;
    margin-top: 2rem;
    font-family: "Stratos", "open-sans";

    &--sub {
      font-size: 1.2rem;
      border-bottom: 1px solid #c4c4c4;
      line-height: 2;
      padding-bottom: 0.5rem;
      width: 100%;
    }
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
