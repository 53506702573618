.displayQuestionsBlock {
  margin-top: 2rem;

  &__button-row {
    margin: 3rem 0;
    padding-left: 1.5rem;
  }

  &__question {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__white-box {
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    max-width: 900px;

    @media only screen and (max-width: $bp-lg) {
      max-width: 100%;
    }

    &__button {
      font-size: 0.75rem;
      color: #757575;
      padding: 0.5rem;
      width: min-content;
      border: none;
    }

    &__radio-row {
      margin-left: 1.5rem;
      margin-top: 2rem;
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-family: "Open Sans", sans-serif;
      font-size: 0.9rem;

      @media only screen and (max-width: $bp-xs) {
        margin: 1rem 0;
        justify-content: space-evenly;
      }

      &__bad {
        margin-right: 1rem;
      }

      &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.8rem;

        @media only screen and (max-width: $bp-xs) {
          margin: 0 0.3rem;
        }

        &__num {
          margin-bottom: 0.3rem;
        }
      }

      & input[type="radio"] {
        display: none;
        visibility: hidden;
      }

      input[type="radio"]:checked
        + .displayQuestionsBlock__white-box__radio-row__check {
        border: 1px solid #ed694a;
      }

      input[type="radio"]:checked
        + .displayQuestionsBlock__white-box__radio-row__check::before {
        background: #ed694a;
      }

      &__check {
        cursor: pointer;
        position: relative;
        border: 1px solid #525252;
        background-color: #eeeeee;
        border-radius: 100%;
        height: 19px;
        width: 19px;
        z-index: 5;
        transition: border 0.25s linear;
        -webkit-transition: border 0.25s linear;

        &:hover {
          border: 2px solid #223b41;
        }

        &::before {
          display: block;
          position: absolute;
          content: "";
          border-radius: 100%;
          height: 13px;
          width: 13px;
          top: 2px;
          right: 2px;
          margin: auto;
          transition: background 0.25s linear;
          -webkit-transition: background 0.25s linear;
        }
      }

      &__mobile-info {
        display: none;
        flex: 0 0 100%;
        justify-content: space-evenly;
        margin-top: 1rem;
      }

      &__good {
        margin-left: 1rem;
      }
    }

    &__question {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__heading {
      margin-top: 0.5rem;
      border: none;
      padding: 0.5rem;
      padding-left: 0;
      font-size: 1.5rem;
      font-weight: 600;

      @media only screen and (max-width: $bp-xs) {
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }

    &__textarea {
      margin-top: 0.5rem;
      border: none;
      padding: 0.5rem;
      font-family: "Open Sans", sans-serif;
      font-size: 0.9rem;
      line-height: 1.5;
      margin-left: 1.5rem;
      outline: #e4e4e4 1px solid;
      color: #161515;

      @media only screen and (max-width: $bp-lg) {
        margin-left: 0;
      }
    }
  }
}
