.leftNavbar {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;

  &__link {
    padding: 0.5rem 0rem;
    padding-left: 1.2rem;
    color: black;
    line-height: 1.4rem;
    width: 100%;
    font-size: 0.8rem;
    word-break: break-all;
    transition: all 0.2s;

    &:hover {
      color: #ed694a;
      background-color: #f6f6f6;
      text-decoration: none;
    }

    &--active {
      background-color: #f6f6f6;
      text-decoration-line: underline;
    }
  }
}
