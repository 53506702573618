.customdropdown {
  margin-top: 1rem;
  height: 60px;
  font-family: "Open sans", "sans-serif";
  height: 60px;
  width: 510px;
  position: relative;

  &__dropdown-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    cursor: pointer;

    &--employee-theme {
      line-height: 1.5;
      font-family: "Stratos";
      margin-top: 0.5rem;
      font-weight: 400;
    }
  }

  &-list {
    padding: 1rem;
    position: absolute;
    z-index: 1;
    right: 0;
    text-align: left;
    line-height: 1.5;
    background-color: #fff;
    width: 100%;

    &__item {
      font-family: "Stratos", "sans-serif";
      color: #161515;
      font-size: 1rem;
      padding: 0.5rem;
      cursor: pointer;
      padding-left: 1rem;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  &-list__search-row {
    display: flex;
    background-color: #e2e2e2;
    height: 40px;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
  }

  &__search {
    background-color: #e2e2e2;
    font-family: "Open Sans", "sans-serif";
    width: 100%;
    padding: 0.5rem;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }
  }
}
