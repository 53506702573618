.HomeView {
  &__topsection {
    background-color: #223b41;
    @media only screen and (max-width: $bp-md) {
      padding-bottom: 3rem;
    }

    &__heading-1 {
      color: #a7e8e0;
      margin-top: 6rem;
      text-align: left;
      font-family: stratos, sans-serif;
      font-size: 3.6rem;
      line-height: 1.4;

      @media only screen and (max-width: $bp-md) {
        margin-top: 2rem;
        font-size: 2.8rem;
      }

      @media only screen and (max-width: $bp-sm) {
        margin-top: 1rem;
        font-size: 2rem;
      }
    }

    &__textinput {
      height: 3.75rem;
      padding: 1rem;
      margin-top: 2rem;
      width: 378px;
      max-width: 100%;
      border: none;
      font-family: "Open Sans", "sans-serif";
      @media only screen and (max-width: $bp-sm) {
        width: 60%;
      }
      @media only screen and (max-width: $bp-xs) {
        width: 50%;
      }

      &__button {
        background-color: #a7e8e0;
        height: 3.75rem;
        border: none;
        padding: 0 2rem;
        @media only screen and (max-width: $bp-xs) {
          width: 50%;
          padding: 0 0.5rem;
        }
      }

      &:focus {
        outline: none;
        border: none;
      }
    }

    &__figure {
      height: 510px;
      max-width: 100%;
      margin: 0;
      margin-top: 5rem;
      margin-bottom: -4rem;
      @media only screen and (max-width: $bp-md) {
        display: none;
        margin-bottom: 3rem;
      }

      &__image {
        height: 100%;
        object-fit: cover;
        max-width: 100%;
      }
    }

    &__text {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-top: 1rem;
      color: white;
      font-family: "Open Sans", "sans-serif";
    }
  }

  &__middlesection {
    height: 390px;
    @media only screen and (max-width: $bp-md) {
      height: 100%;
    }

    &__heading {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 3.5rem;
      max-width: 90%;

      @media only screen and (max-width: $bp-md) {
        font-size: 2.2rem;
        max-width: 100%;
      }

      @media only screen and (max-width: $bp-xs) {
        font-size: 1.8rem;
        line-height: 1.5;
        padding: 1rem;
      }
    }

    &__blackbox {
      height: 100%;
      background-color: black;
      margin-left: 15px;
      color: white;

      @media only screen and (max-width: $bp-md) {
        margin-left: 0;
        padding: 2rem;
      }

      &__heading {
        margin-top: 5rem;
        font-size: 1.5rem;
        color: #a7e8e0;
        font-weight: 600;

        @media only screen and (max-width: $bp-md) {
          margin-top: 0;
        }
      }

      &__text {
        font-family: "Open Sans", "Sans Serif";
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: 1rem;
      }
    }
  }

  &__bottomsection {
    height: 1190px;
    background-color: #f6f6f6;
    @media only screen and (max-width: $bp-md) {
      height: 100%;
    }

    &__row {
      justify-content: space-between;

    }

    &__button {
      background-color: #ed694a;
      color: white;
      padding: 1rem 3rem;
      margin-top: 2rem;
      border: none;
      cursor: pointer;
    }

    &__columns {
     
      &__heading {
        font-weight: 600;
        margin-top: 1.8rem;
        font-size: 1.5rem;
      }

      &__figure {
        max-height: 150px;
        max-width: 100%;
        display: flex;
        justify-content: center;
      }

      &__image {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      &__text {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 0;
        font-family: "Open Sans", "Sans Serif";

        @media only screen and (max-width: $bp-lg) {
          margin-bottom: 3rem;
        }
      }

       
    }

    &__dashboard-image {
      background-color: #c4c4c4;
      height: 420px;
      margin-top: 7.5rem;
      margin-bottom: 7.5rem;
      @media only screen and (max-width: $bp-md) {
        margin: 3rem 0;
      }
    }

    &__heading {
      margin-top: 7.5rem;
      font-size: 3.1rem;
      font-weight: 600;

      @media only screen and (max-width: $bp-md) {
        margin-top: 4rem;
      }
    }

    &__text {
      font-family: "Open Sans", "Sans Serif";
      margin-top: 1rem;
      line-height: 1.4rem;
    }
  }
}
