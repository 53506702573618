.surveyOverview {
  height: 240px;
  background-color: #ffffff;
  margin-top: 2rem;
  flex: 0 1 33%;
  max-width: 50%;
  padding: 0.6rem;
  position: relative;
  color: #6e6e6e;
  text-decoration: none;
  margin-right: 2%;

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    text-decoration: none;
    color: #161515;
  }

  &__bottomRow-info {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0.6rem;
    width: 100%;
    padding-right: 1.2rem;

    &__icon {
      padding: 1rem;
      background-color: #eeeeee;

      &--checked {
        background-color: #A7E8E0;
      }
    }

    &__numbers {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: 600;

      &-text {
        padding-top: 0.2rem;
        font-size: 0.7rem;
        line-height: 0.9;
        color: #6e6e6e;
        font-weight: 400;
      }
    }
  }

  &__heading {
    color: #161515;
    font-size: 1.5rem;
    line-height: 2;
    font-weight: 600;
  }

  &__workspace-info {
    display: flex;
    justify-content: space-between;
    line-height: 1.25;
    margin-top: 0.3rem;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 0.4rem;
     color: #6e6e6e;
  }

  &__question {
    font-family: "Open sans", "sans-serif";
    font-size: 0.95rem;
    line-height: 1.4;
    color: #6e6e6e;
    margin-top: 0.5rem;
  }
}
