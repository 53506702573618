/////////// CONSTANTS
$breakpoints: (
  "xs": 576px,
  "sm": 767px,
  "md": 992px,
  "lg": 1200px,
) !default;

/////////// HELPER CLASSES
.App {
  &__brand {
    font-family: "Sulphur Point", sans-serif;
    font-size: 1rem;
    transition: all 0.2s;

    &:hover {
      color: #a7e8e0 !important;
    }
  }

  &__button {
    padding: 1rem 3rem;
    margin-top: 1rem;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--small {
      padding: 1rem 2rem;
    }
  }

  &__button--green {
    background-color: #a7e8e0;
    color: black;
  }

  &__button--light-grey {
    background-color: #eeeeee;
    color: #757575;
  }

  &__button--orange {
    background-color: #ed694a;
    color: white;

    &:focus {
      outline: white;
    }
  }

  &__dashboard__info-text {
    width: 50%;
    font-family: "Open Sans", "sans serif";
    font-size: 0.9rem;
    line-height: 1.5;
    margin-top: 1.8rem;

    &--full-width {
      width: 100%;
    }
  }

  &__dashboard__heading {
    font-size: 1.5rem;
    line-height: 2;
    font-weight: 600;
    border-bottom: 1px solid #c4c4c4;
    margin-top: 3rem;

    &--no-border {
      border-bottom: none;
    }

    &--green {
      color: #a7e8e0;
    }

    &__subtext {
      text-align: right;
      font-family: 'Open Sans';
      font-size: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      margin-right: 1rem;

      &__icon {
        padding-bottom: 3px;
      }
    }
  }

  &__dashboard__heading--large {
    font-size: 2.8rem;
    line-height: 2;
    font-weight: 600;
    border-bottom: 1px solid #c4c4c4;
  }

  &__dashboard-surveyOverview-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;

    & a:last-of-type {
      margin-right: 0;
    }
  }

  &__flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__textinput {
    height: 3.75rem;
    padding: 1rem;
    margin-top: 2rem;
    width: 378px;
    border: none;

    &__button {
      background-color: #a7e8e0;
      height: 3.75rem;
      border: none;
      padding: 0 2rem;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  &-small {
    &-hide {
      @media only screen and (max-width: $bp-sm) {
        display: none;
      }
    }
  }

  &-mobile {
    &-hide {
      @media only screen and (max-width: $bp-xs) {
        display: none;
      }
    }

    &-show {
      display: none;
      @media only screen and (max-width: $bp-xs) {
        display: flex !important;
      }
    }
  }
}

////////// RESET

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-family: "Stratos", "sans-serif";
  font-weight: 400;
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
