.editStaffCard {
  flex: 0 0 47%;
  background-color: #ffffff;
  height: 58px;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans", "sans-serif";
  position: relative;

  &__dropdown {
    &-list {
      position: absolute;
      z-index: 1;
      right: 0;
      text-align: left;
      line-height: 1.5;
      width: 180px;
      background-color: #e5e5e5;

      &__item {
        font-family: "Stratos", "sans-serif";
        color: #161515;
        font-size: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        padding-left: 1rem;

        &:hover {
          background-color: #f6f6f6;
        }
      }

      &__sublist {
        position: absolute;
        left: 5px;
        top: 10px;
      }
    }
  }

  &__edit-dots {
    margin-left: 0.8rem;
    cursor: pointer;
  }

  &__email {
    font-size: 0.75rem;
    font-weight: 700;
    color: #afafaf;
    text-align: right;
    display: flex;
    align-items: center;

    &__input {
      text-align: right;
      // width: min-content;
      &:disabled {
        background-color: transparent;
        border: none;
        text-decoration: none;
      }
    }
  }

  &__name {
    font-size: 0.9rem;
    margin-right: 0.5rem;
    line-height: 1.2;
  }
}
