@use '../variables';

.formView {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__green-box {
    background-color: #223b41;
    height: 280px;
    width: 100%;

    &__content {
      height: 60%;
    }

    &__heading {
      font-size: 2.8rem;
      font-weight: 600;
      font-family: "Stratos", sans-serif;
      color: #a7e8e0;

      @media only screen and (max-width: $bp-md) {
        font-size: 2rem;
      }
    }

    &__info-text {
      color: white;
      font-family: "Open Sans";
      line-height: 1.3;
      font-size: 1rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-md) {
        font-size: 0.9rem;
      }
    }
  }
}
